import { API_URLS } from "constants";
import { POST } from "helpers";

// const server = "http://localhost:3000";
// const server = "https://lk.socmedica.dev"; 
// const server = "https://lk.socmedica.com"; 


const backServer = window.location?.origin || "https://lk.socmedica.com";

export function fetchGetGetBalance({ authKey }) {
  return POST({
    path: API_URLS.wallet.GetBalance,
    data: {
      authKey,
    },
  });
}

export function fetchGetReplenishmentBalance({ authKey, amount }) {

  return POST({
    path: API_URLS.wallet.GetReplenishmentBalance,
    data: {
      authKey: authKey,
      returnUrl: `${backServer}/paymentAnswer/success/`,
      failUrl: `${backServer}/paymentAnswer/fail/`,
      amount: amount.toFixed(2)
    },
  });
}

