import React, { memo, useEffect } from "react";
import { useParams } from 'react-router-dom';


const BankAnswerComponent = () => {
    const { status, orderNumber } = useParams();

    console.log("orderNumber", orderNumber);
    
    useEffect(() => {
        if (window && window.parent) {
            window.parent.postMessage({ type: "MSG_BankAnswer", message: status }, '*');
        }
    }, [status]);

    return <>
        <div>Процесс оплаты завершён.</div>
    </>;
}

export const BankAnswer = memo(BankAnswerComponent);
