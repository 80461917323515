import React, { memo, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Table,
  TableBody,
  useMediaQuery,
  TableCell,
  TableRow,
  Tabs,
  Tab,
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import CheckIcon from "@mui/icons-material/Check"

import { Dialog } from "UI";

import {
  sendGetApplicationInfo,
  selectCurrentApplicationId,
  selectCurrentApplicationList,
  selectLoading,
  selectApplicationInfoByid,
  resetCurrentApplication,
} from "features/pages/pagesSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ApplicationInfoComponent = () => {
  const [tab, setTab] = React.useState(0);

  const { applicationInfo: loading } = useSelector(selectLoading);

  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const currentApplicationId = useSelector(selectCurrentApplicationId);
  const applicationTabs = useSelector(selectCurrentApplicationList);
  const applicationCurrentTab = useMemo(() => applicationTabs[tab] || {}, [applicationTabs, tab]);
  const applicationInfo = useSelector((state) => selectApplicationInfoByid(state, applicationCurrentTab.id));

  const handleClose = () => {
    dispatch(resetCurrentApplication());
    setTab(0);
  };

  const handleChangeTab = (_, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (applicationCurrentTab.id) {
      dispatch(sendGetApplicationInfo({ id: applicationCurrentTab.id }));
    }
  }, [dispatch, applicationCurrentTab]);


  const rows = [
    {
      name: "Статус",
      value: applicationInfo.statusText,
    },
    {
      name: "Ключ",
      value: applicationInfo.licenseKey,
    },
    {
      name: "Срок действия ключа (в днях)",
      value: applicationInfo.validity,
    },
    {
      name: "Обновление по факту",
      value: !!applicationInfo.updateAfterTheFact ? <CheckIcon color="primary" /> : false,
    },
    {
      name: "Обновление каждые N дней",
      value: applicationInfo.updateEveryNDays,
    },
    {
      name: "Лимит по частоте запросов (интервал между запросами в секундах)",
      value: applicationInfo.rateLimit,
    },
    {
      name: "Лимит по количеству запросов за минуту",
      value: applicationInfo.minuteLimit,
    },
    {
      name: "Лимит по количеству запросов за сутки",
      value: applicationInfo.dayLimit,
    },
    {
      name: "Лимит по общему количеству запросов",
      value: applicationInfo.totalLimit,
    },
    {
      name: "Общее количество удачных запросов",
      value: applicationInfo.totalRequest,
    },
    {
      name: "Количество удачных запросов за сутки",
      value: applicationInfo.dayRequest,
    },
    {
      name: "Время последнего запроса",
      value: applicationInfo.lastRequest > 0 ? new Date(applicationInfo.lastRequest).toLocaleString() : "",
    },
  ];

  const rowsFiltered = rows.filter(row => row.value);

  return (applicationInfo &&
    <Dialog
      fullScreen={fullScreen}
      open={!!currentApplicationId}
      onClose={handleClose}
      title={applicationInfo.name}
      loading={loading}
    >

      <Box sx={{ borderBottom: 1, borderColor: 'divider', minWidth: "400px" }}>
        {applicationTabs.length > 1 && <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
          {applicationTabs.map((tab, index) => <Tab key={tab.id} label={<>{`Ключ ${index + 1}`}<Typography sx={{ fontSize: 10 }}>{tab.licenseKey}</Typography></>} />)}
        </Tabs>}
      </Box>
      <TabPanel value={tab} index={tab}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {rowsFiltered.map((row) => (
              <TableRow
                key={row.name}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>

                <TableCell align="right">
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TabPanel>

    </Dialog>
  );
}

export const ApplicationInfo = memo(ApplicationInfoComponent);