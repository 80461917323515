export { API_URLS } from "./APIUrls";

export const DRAWER_WIDTH = 340;

export const WALLET = {
    defaultAmount: 200
};

export const TXT = {
    patientList: "Мои пациенты",
    wallet: "Кошелёк",
    friendList: "Мои коллеги",
    applicationList: "Мои приложения",
    inviteColleague: "Пригласить коллегу",
}

export const TABLE_ROW_STATUSES = {
    "Active": {
        color: "#36dcb5",
        title: "Активно",
    },
    "Unactive": {
        color: "#d96a82",
        title: "Неактивно",
    },
    "Default": {
        color: "inherit",
        title: ""
    }
};

// COLORS[index % COLORS.length

export const COLORS = [
    "#d7263d",
    "#2364aa",
    "#c5d86d",
    "#f46036",
    "#2e294e",
    "#fac748",
    "#f88dad",
    "#1b998b",
    "#c5d86d",
    "#1d2f6f",
    "#8390fa",
    "#3da5d9",
    "#73bfb8",
    "#fec601",
    "#ea7317",
];


// export const COLORS = [
//     "#fd7f6f",
//     "#7eb0d5",
//     "#b2e061",
//     "#bd7ebe",
//     "#ffb55a",
//     "#ffee65",
//     "#beb9db",
//     "#fdcce5",
//     "#8bd3c7",
//     "#ea5545",
//     "#f46a9b",
//     "#ef9b20",
//     "#edbf33",
//     "#ede15b",
//     "#bdcf32",
//     "#87bc45",
//     "#27aeef",
//     "#b33dc6",
// ];