import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetGetBalance, fetchGetReplenishmentBalance } from "./walletAPI";

const initialState = {
  wallet: {},
  process: {
    orderId: null,
    formUrl: "",
  },
  balance: 0,
  loading: {
    wallet: false,
  }
};

export const sendGetBalance = createAsyncThunk(
  "wallet/sendGetBalance",
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await fetchGetGetBalance({ authKey: auth.authKey });

    return response;
  }
);

export const sendGetReplenishmentBalance = createAsyncThunk(
  "wallet/sendGetReplenishmentBalance",
  async (props, { getState }) => {
    const { amount } = props;
    const { auth } = getState();
    const response = await fetchGetReplenishmentBalance({ authKey: auth.authKey, amount });

    return response;
  }
);



export const authSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    // setLoading: (state, action) => {
    //   state.loading.wallet = action.payload.data;
    // },
    updateProcess: (state, action) => {
      state.process = {
        ...state.process,
        ...action.payload
      };
    },

    // resetCurrentService: (state) => {
    //   state.serviceApplicationId = initialState.serviceApplicationId;
    //   state.servicecsInfo = initialState.servicecsInfo;
    // },
  },

  extraReducers: (builder) => {
    builder

      .addCase(sendGetBalance.pending, (state) => {
        state.status = "loading";
        state.loading.wallet = true;
      })

      .addCase(sendGetBalance.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.wallet = false;

        let data = action.payload.data || {};

        const walletData = data;

        state.balance = walletData.coins || 0;
      })

      .addCase(sendGetBalance.rejected, (state, action) => {
        state.status = "idle";
        state.loading.wallet = false;
      })

      .addCase(sendGetReplenishmentBalance.pending, (state) => {
        state.status = "loading";
        state.loading.wallet = true;
      })

      .addCase(sendGetReplenishmentBalance.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.wallet = false;

        let data = action.payload.data || {};
        state.process.formUrl = data.formUrl;
      })

      .addCase(sendGetReplenishmentBalance.rejected, (state, action) => {
        state.status = "idle";
        state.loading.wallet = false;
      })







      ;
  },
});

export const {
  updateProcess,
  //   setLoading,
} = authSlice.actions;

export const selectBalance = (state) => state.wallet.balance;
export const selectLoading = (state) => state.wallet.loading;
export const selectProcess = (state) => state.wallet.process;

export default authSlice.reducer;
