import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetApplicationList, fetchGetApplicationInfo, fetchGetServiceList, fetchGetServiceInfo, fetchSetPurchaseService, fetchGetMyServices, } from "./pagesAPI";

const getSrcImage = (srNumber) => {
  let src = ``;
  switch (srNumber) {
    case 1:
    case 2:
    case 4:
    case 108:
    case 204:
      src = `/img/ecp.png`;
      break;
    case 5:
    case 6:
    case 7:
    case 15:
      src = `/img/pharmtaxi.png`;
      break;
    case 16:
    case 46:
    case 182:
    case 183:
      src = `/img/eat.png`;
      break;
    case 47:
      src = `/img/skol.png`;
      break;
    case 298:
      src = `/img/umkb.png`;
      break;
    default:
      break;
  }

  return src;
};

const getStatusText = (item) => {
  let statusText = "";
  switch (item.status) {
    case "disabled":
      statusText = `отключена`;
      break;
    case "locked":
      statusText = `заблокирована`;
      break;
    case "notActive":
      statusText = `не активна`;
      break;
    case "active":
      statusText = `активна до ${new Date(item.dateStop).toLocaleString()}`;
      break;
    case "unlimited":
      statusText = `бессрочно`;
      break;
    default:
      statusText = ``;
      break;
  }

  return statusText;
};


const initialState = {
  list: [],
  serviceList: [],
  currentApplicationId: null,
  serviceApplicationId: null,
  applicationsInfo: {},
  servicecsInfo: {},
  loading: {
    applicationInfo: false,
    applicationList: false,
    serviceInfo: false,
    serviceList: false,
  }
};

export const sendSetPurchaseService = createAsyncThunk(
  "pages/sendSetPurchaseService",
  async (props, { getState }) => {
    const { data, success } = props;
    const { count, cost, serviceId } = data;
    const { auth } = getState();
    const response = await fetchSetPurchaseService({
      data: {
        authKey: auth.authKey,
        serviceId,
        count: +count,
        cost: cost.toFixed(2)
      },
      success
    });

    return response;
  }
);

export const sendGetApplicationList = createAsyncThunk(
  "pages/fetchSendGetApplicationList",
  async (_, { getState }) => {
    const { auth } = getState();
    const response = await fetchGetApplicationList({ key: auth.key, authKey: auth.authKey });

    return response;
  }
);

export const sendGetApplicationInfo = createAsyncThunk(
  "pages/fetchSendGetApplicationInfo",
  async (props, { getState }) => {

    const { id } = props;
    const { auth } = getState();
    const response = await fetchGetApplicationInfo({ key: auth.key, authKey: auth.authKey, id });
    return response;
  }
);

export const sendGetServiceList = createAsyncThunk(
  "pages/fetchSendGetServiceList",
  async (props, { getState }) => {
    const { id } = props;
    const { auth } = getState();
    let combinedResponse = [];
    const response = await fetchGetServiceList({ key: auth.key, authKey: auth.authKey, id });
    const response2 = await fetchGetMyServices({ authKey: auth.authKey });


    if (response && response.data && Array.isArray(response.data)) {
      if (response2 && response2.data && Array.isArray(response2.data)) {

        combinedResponse = response.data.map(item => {
          return {
            ...item,
            myServices: response2.data.filter(item2 => item2.serviceId === item.id)
          };
        });
      }
    }

    console.log("combinedResponse", combinedResponse);
    return combinedResponse;
  }
);

export const sendGetServiceInfo = createAsyncThunk(
  "pages/fetchSendGetServiceInfo",
  async (props, { getState }) => {

    const { id } = props;
    const { auth } = getState();
    const response = await fetchGetServiceInfo({ key: auth.key, authKey: auth.authKey, id });
    return response;
  }
);

export const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setCurrentApplicationId: (state, action) => {
      state.currentApplicationId = action.payload;
    },
    setServiceApplicationId: (state, action) => {
      state.serviceApplicationId = action.payload;
    },
    resetCurrentApplication: (state) => {
      state.currentApplicationId = initialState.currentApplicationId;
      state.applicationsInfo = initialState.applicationsInfo;
    },
    resetCurrentService: (state) => {
      state.serviceApplicationId = initialState.serviceApplicationId;
      state.servicecsInfo = initialState.servicecsInfo;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(sendGetApplicationList.pending, (state) => {
        state.status = "loading";
        state.loading.applicationList = true;
      })

      .addCase(sendGetApplicationList.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.applicationList = false;

        let data = action.payload.data || {};

        const getNumberOfKeysByService = (id, serviceNumber) => {
          const sameServiceNumber = data.filter(item => item.serviceNumber === serviceNumber) || [];

          // Нужен только первый, остальные остаются в списке, но у них numberOfKeys устанавливаем в 0 и не отображаем в списке
          if (id === sameServiceNumber[0].id) {
            return sameServiceNumber.length;
          }
          return 0;
        }

        const applicationList = data.map(item => {
          return {
            ...item,
            avatar: getSrcImage(item.serviceNumber),
            statusText: getStatusText(item),
            numberOfKeys: getNumberOfKeysByService(item.id, item.serviceNumber),
          };
        });

        state.list = applicationList;
      })

      .addCase(sendGetApplicationList.rejected, (state, action) => {
        state.status = "idle";
        state.loading.applicationList = false;
      })




      .addCase(sendGetServiceList.pending, (state) => {
        state.status = "loading";
        state.loading.serviceList = true;
      })

      .addCase(sendGetServiceList.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.serviceList = false;

        let data = action.payload || [];

        const getNumberOfKeysByService = (id, serviceNumber) => {
          const sameServiceNumber = data.filter(item => item.serviceNumber === serviceNumber) || [];

          // Нужен только первый, остальные остаются в списке, но у них numberOfKeys устанавливаем в 0 и не отображаем в списке
          if (id === sameServiceNumber[0].id) {
            return sameServiceNumber.length;
          }
          return 0;
        }

        const serviceList = data.map(item => {
          return {
            ...item,
            avatar: getSrcImage(item.serviceNumber),
            statusText: getStatusText(item),
            numberOfKeys: getNumberOfKeysByService(item.id, item.serviceNumber),
          };
        });

        state.serviceList = serviceList;
      })

      .addCase(sendGetServiceList.rejected, (state, action) => {
        state.status = "idle";
        state.loading.serviceList = false;
      })





      .addCase(sendGetApplicationInfo.pending, (state) => {
        state.status = "loading";
        state.loading.applicationInfo = true;
      })

      .addCase(sendGetApplicationInfo.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.applicationInfo = false;
        const id = action?.meta?.arg?.id || null;

        if (!id) {
          return;
        }

        let data = action.payload.data || {};

        state.applicationsInfo[id] = {
          ...data,
          avatar: getSrcImage(data.serviceNumber),
          statusText: getStatusText(data),
        };
      })

      .addCase(sendGetApplicationInfo.rejected, (state, action) => {
        state.status = "idle";
        state.loading.applicationInfo = false;
      })




      .addCase(sendSetPurchaseService.pending, (state) => {
        state.status = "loading";
        state.loading.wallet = true;
      })

      .addCase(sendSetPurchaseService.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.wallet = false;

        console.log(action.payload);
      })

      .addCase(sendSetPurchaseService.rejected, (state, action) => {
        state.status = "idle";
        state.loading.wallet = false;
      })













      ;
  },
});

export const {
  setCurrentApplicationId,
  resetCurrentApplication,
  setServiceApplicationId,
  resetCurrentService,
} = pagesSlice.actions;

export const selectApplicationList = (state) => state.pages.list;
export const selectLoading = (state) => state.pages.loading;
export const selectCurrentApplicationId = (state) => state.pages.currentApplicationId;
export const selectCurrentApplicationList = (state) => state.pages.list.filter(item => item.serviceNumber === state.pages.currentApplicationId) || [];
export const selectApplicationsInfo = (state) => state.pages.applicationsInfo;
export const selectApplicationInfoByid = (state, id) => state.pages.applicationsInfo[id] || {};

export const selectServiceList = (state) => state.pages.serviceList;
export const selectServiceApplicationId = (state) => state.pages.serviceApplicationId;
export const selectCurrentServiceList = (state) => state.pages.serviceList.filter(item => item.serviceNumber === state.pages.serviceApplicationId) || [];
export const selectServiceInfoByid = (state, id) => state.pages.servicecsInfo[id] || {};

export default pagesSlice.reducer;
