import React, { memo, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import clsx from 'clsx';
import { checkErrors, fireToast } from 'helpers';
import { WALLET } from 'constants';
// import { QRCodeSVG } from 'qrcode.react';

import {
    Box,
    Button,
    Typography,
    Card,
    FormControl,
    OutlinedInput,
    InputAdornment,
    Chip,
    LinearProgress,
} from '@mui/material';

import { Loader, Dialog } from "UI";
import {
    selectLoading,
    selectBalance,
    selectProcess,
    sendGetBalance,
    sendGetReplenishmentBalance,
    updateProcess,
} from "features/wallet/walletSlice";


import styles from './Wallet.module.css';


const BankFrame = ({ formUrl }) => {
    return <Box
        component={"iframe"}
        title="Оплата"
        loading="lazy"
        sx={{
            width: "100%",
            height: "100%"
        }}
        src={formUrl}
    />;
}


const WalletComponent = () => {
    const dispatch = useDispatch();
    let { wallet: loading } = useSelector(selectLoading);

    const balance = useSelector(selectBalance);
    const { formUrl } = useSelector(selectProcess);
    // const formUrl = "http://localhost:3000/paymentAnswer/success/c5f36383903546b6a0be8432ee353880?orderId=eed762ad-f961-7780-bb2c-d5ad281172b9&lang=ru";
    const [openPayDialog, setOpenPayDialog] = useState(false);
    const [amount, setAmount] = useState(WALLET.defaultAmount);


    const handleClickPay = () => {
        dispatch(sendGetReplenishmentBalance({ amount }));
    }

    const handleClosePayDialog = useCallback(() => {
        setOpenPayDialog(false);

        // if (formUrl) {
        //     setTimeout(() => {
        //         dispatch(updateProcess({ formUrl: "" }));
        //     }, 3000);
        // }
    }, []);

    const handleOpenPayDialog = () => {
        setOpenPayDialog(true);
    }

    const handleChangeAmount = (event) => {
        let amount = event.target.value;

        const formErrors = checkErrors({ amount }, [
            { name: "amount", type: "number", required: true },
        ]);

        if (!Object.keys(formErrors).length || amount === "") {
            setAmount(+amount);
        }
    }
    const handleSetAmount = (amount) => {
        setAmount(+amount);
    }


    const messageListener = useCallback(event => {
        if (event.origin.startsWith('http://localhost') || event.origin.startsWith('https://lk.socmedica.')) {

            if (event.data.type === "MSG_BankAnswer") {
                if (event.data.message === "success") {
                    handleClosePayDialog();
                    fireToast({ message: "Оплата прошла успешно. Может понадобится несколько минут прежде чем деньги поступят на счёт", level: "success" });
                    dispatch(updateProcess({ formUrl: "" }));
                    setTimeout(() => {
                        dispatch(sendGetBalance());
                    }, 3000);
                }

                if (event.data.message === "fail") {
                    fireToast({ message: "Оплата не прошла. Возникла ошибка во время процесса пополнения счёта", level: "error" });
                }
            }
        } else {
            console.log("NO", event.data);

            return;
        }
    }, [dispatch, handleClosePayDialog]);

    useEffect(() => {
        window.removeEventListener('message', messageListener);
        window.addEventListener('message', messageListener);
    }, [messageListener]);


    useEffect(() => {
        dispatch(sendGetBalance());
    }, [dispatch]);

    console.log("RENDER");
    return <>
        <Box sx={{ height: "calc(100vh - 142px)", width: '100%' }}>
            <Card
                sx={{
                    margin: "10px",
                    padding: "20px",
                    // width: "100%",
                    // display: "flex",
                    alignItems: "baseline",
                    flexDirection: "column",
                }}
            >
                <Box sx={{
                    display: "flex",
                    alignItems: "baseline",
                    flexDirection: "row",
                }}>
                    <Loader
                        loading={loading}
                        variant="replace"
                        replacer={<LinearProgress sx={{ width: "100%" }} />}
                        sx={{ justifyContent: "left", opacity: 0.9 }}
                    >
                        <Typography variant="h1" sx={{ minWidth: "50px" }}>{balance}</Typography>

                    </Loader>
                    {!loading && <Typography variant="overline">Ucoins</Typography>}
                </Box>
                <Button variant="outlined" onClick={handleOpenPayDialog}>Пополнить</Button>
            </Card>
        </Box>

        <Dialog
            title={"Пополнить счёт"}
            onClose={handleClosePayDialog}
            open={openPayDialog}
            closeButton={false}
            classes={{
                paper: clsx(formUrl && styles.dialogFullHeight)
            }}

            actions={formUrl ? [] : [
                {
                    title: "Пополнить",
                    fullWidth: true,
                    onClick: handleClickPay,
                    variant: "contained"
                }
            ]}
        >
            {formUrl ? <BankFrame formUrl={formUrl} /> :
                <><FormControl
                    sx={{ mt: 2, mb: 2 }}
                    variant="outlined"
                    fullWidth

                >
                    <OutlinedInput
                        value={amount}
                        placeholder="Сумма"
                        endAdornment={<InputAdornment position="end">₽</InputAdornment>}
                        onChange={handleChangeAmount}
                        sx={{
                            fontSize: 30,
                        }}
                        inputProps={{
                            sx: {
                                m: 0,
                                p: 2
                            }
                        }}
                    />
                </FormControl>
                    <Box
                        sx={{
                            display: "flex",
                            gap: "4px",
                        }}>

                        <Chip label="100" onClick={() => handleSetAmount(100)} />
                        <Chip label="200" onClick={() => handleSetAmount(200)} />
                        <Chip label="500" onClick={() => handleSetAmount(500)} />
                        <Chip label="1000" onClick={() => handleSetAmount(1000)} />
                        <Chip label="2000" onClick={() => handleSetAmount(2000)} />
                        <Chip label="5000" onClick={() => handleSetAmount(5000)} />
                    </Box>
                </>}
        </Dialog>
    </>;
}

export const Wallet = memo(WalletComponent);
