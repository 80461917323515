import React, { memo, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { checkErrors } from 'helpers';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Zoom,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
} from "@mui/material";

import WorkIcon from "@mui/icons-material/Work";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ArrowForwardIosIcon from "@mui/icons-material/NavigateNext";

import {
  selectLoading,
  sendSetPurchaseService,
  setServiceApplicationId,
} from "features/pages/pagesSlice";


import { Dialog } from "UI";


const ServiceItemComponent = (props) => {
  const dispatch = useDispatch();
  let { wallet: loading } = useSelector(selectLoading);

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [count, setCount] = useState(1);
  const [myServicesCount, setMyServicesCount] = useState(props.model?.myServices?.length || 0);
  let options = props.model.serviceOptions || [];


  const handleCloseAcceptDialog = useCallback(() => {
    setOpenAcceptDialog(false);
    setCount(1);
  }, []);

  const handleOpenAcceptDialog = useCallback(() => {
    setOpenAcceptDialog(true);
  }, []);


  const handleClickAccept = useCallback(() => {
    dispatch(sendSetPurchaseService({
      data: {
        count,
        cost: props.model.price,
        serviceId: props.model.id,
      },
      success: () => setMyServicesCount(myServicesCount + count)
    }));


    handleCloseAcceptDialog();


    // setTimeout(() => {
    //   dispatch(sendGetServiceList({ id: props.model.id }));
    // }, 2000);

  }, [count, dispatch, handleCloseAcceptDialog, myServicesCount, props.model.id, props.model.price]);


  const handleChangeCount = useCallback((event) => {
    let count = event.target.value;

    const formErrors = checkErrors({ count }, [
      { name: "count", type: "number", required: true },
    ]);

    if ((!Object.keys(formErrors).length || count === "") && count <= 999) {
      setCount(+count);
    }
  }, []);


  useEffect(() => {

    // reset on unMount
    return () => {
      dispatch(setServiceApplicationId(null));
    }
  });


  return (<Zoom in={true} >
    <Grid sx={{
      transition: "all, 0.5",
      width: mediumScreen ? "97%" : "unset",
    }}>
      <Card
        sx={{
          width: mediumScreen ? "97%" : "350px",
          margin: "10px",
        }}
      >
        <CardHeader
          titleTypographyProps={{ variant: "subtitle2" }}
          subheaderTypographyProps={{ variant: "subtitle1" }}
          sx={{ pb: "0px", alignItems: "flex-start" }}

          avatar={
            <Avatar
              src={props.model.avatar}
              sx={!smallScreen ? { width: 62, height: 62 } : {}}
            >
              <WorkIcon fontSize="large" sx={{ fontSize: smallScreen ? "32px" : "42px" }} />
            </Avatar>
          }
          title={props.model.name}
          subheader={`Цена: ${props.model.price}`}
        />
        {/* <CardActions disableSpacing>
          <Grid container>
            <Grid
              item
              xs={8}
            >
              <IconButton color="info" aria-label="keys" title="Ключи" onClick={handleClickTitle}>
                <KeyIcon />
              </IconButton>
              <IconButton color="info" aria-label="service" title="Услуги" onClick={handleClickService}>
                <WorkIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton title="Перейти в приложение" aria-label="Launch" onClick={handleLaunchApp}>
                <LaunchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CardActions> */}<CardContent>
          {myServicesCount ?
            <List dense>

              <ListItem key={"myServices_1"}>
                <ListItemIcon
                  sx={{ minWidth: "30px" }}>
                  <TaskAltIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary={<Box color={"success"}>статус: куплено [{`${myServicesCount} шт`}]</Box>}
                />
              </ListItem>
            </List> : null
          }


          {options.length &&
            <List dense>
              {options.map(option =>
                <ListItem key={option.id}>
                  <ListItemIcon
                    sx={{ minWidth: "30px" }}>
                    <ArrowForwardIosIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={option.name}
                  />
                </ListItem>
              )} </List>
          }
        </CardContent>
        <CardActions sx={{ justifyContent: "end" }}>
          <Button variant="outlined" onClick={handleOpenAcceptDialog}>Купить</Button>
        </CardActions>
      </Card>
      <Dialog
        title={`Покупка сервиса "${props.model.name}"`}
        onClose={handleCloseAcceptDialog}
        open={openAcceptDialog}
        closeButton={false}
        // classes={{
        //     paper: clsx(formUrl && styles.dialogFullHeight)
        // }}

        actions={[
          {
            title: "Отменить",
            // fullWidth: true,
            onClick: handleCloseAcceptDialog,
            variant: "outlined",
          },
          {
            title: "Подтвердить",
            // fullWidth: true,
            onClick: handleClickAccept,
            variant: "contained",
            disabled: (!count > 0) || loading
          },
        ]}
      >
        <>

          <FormControl
            sx={{ mt: 2, mb: 2 }}
            variant="outlined"
            fullWidth

          >
            <OutlinedInput
              value={count}
              placeholder="Количество сервисов"
              endAdornment={<InputAdornment position="end">шт</InputAdornment>}
              onChange={handleChangeCount}
              sx={{
                fontSize: 20,
              }}
              inputProps={{
                sx: {
                  m: 0,
                  p: 2
                }
              }}
            />
          </FormControl>

          <Typography>С вашего счёта будет списана сумма в размере <strong>{props.model.price * count} UCOINS</strong></Typography>
        </>
      </Dialog>
    </Grid>



  </Zoom>
  );
}


export const ServiceItem = memo(ServiceItemComponent);