import { API_URLS } from "constants";
import { POST, fireToast } from "helpers";

export function fetchGetApplicationList({ authKey }) {
  return POST({
    path: API_URLS.main.GetApplicationList,
    data: {
      authKey,
    },
  });
}

export function fetchGetApplicationInfo({ authKey, id }) {
  return POST({
    path: API_URLS.main.GetApplicationInfo,
    data: {
      id: +id,
      authKey,
    },
  });
}

export function fetchGetServiceList({ authKey, id }) {
  return POST({
    path: API_URLS.main.GetServiceList,
    data: {
      id: +id,
      authKey,
    },
  });
}

export function fetchGetServiceInfo({ authKey, id }) {
  return POST({
    path: API_URLS.main.GetServiceInfo,
    data: {
      id: +id,
      authKey,
    },
  });
}
export function fetchSetPurchaseService({ data, success }) {

  return POST({
    path: API_URLS.main.SetPurchaseService,
    data,
    success: () => {
      fireToast({ message: "Покупка прошла успешно", level: "success" });
      success && success();
    },
  });
}


export function fetchGetMyServices({ authKey }) {
  return POST({
    path: API_URLS.main.GetMyServices,
    data: {
      authKey,
    },
  });
}
