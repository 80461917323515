import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom"; // useNavigate

import { appStorage } from "helpers";

import { Restricted } from './features/auth';
import { Main } from './features/main';
import { BankAnswer } from './features/wallet'

import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";
import './App.css';

function App() {
  const url = new URL(window.location);

  if (url.pathname) {
    const pathArr = url.pathname.split("/");

    if (pathArr[1] === "welcome" && pathArr[2]) {
      appStorage.setItem("welcomeId", pathArr[2]);
    }
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Restricted>
          <Routes>
              <Route path="*" element={<Main />}/>
                <Route path="paymentAnswer" element={<BankAnswer />}>
                  <Route path=":status/:orderNumber" element={<BankAnswer />} />
                </Route>             
            </Routes>
          </Restricted>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
