import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import { styled } from '@mui/material/styles';

import { DRAWER_WIDTH, TXT } from "constants";
import { appStorage } from "helpers";

import {
    Box,
    Tooltip,
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Avatar,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupIcon from '@mui/icons-material/Group';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DnsIcon from '@mui/icons-material/Dns';
import WalletIcon from '@mui/icons-material/Wallet';

import { selectDrawerOpen, setDrawerOpen, setCurrentPage, selectCurrentPage } from "features/main/mainSlice";

const drawerWidth = DRAWER_WIDTH;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const Bar = () => {
    const open = useSelector(selectDrawerOpen);
    const currentPage = useSelector(selectCurrentPage);
    const dispatch = useDispatch();

    // const authData = useSelector(selectAuthData);
    // let displayName = (authData.firstName || authData.lastName || authData.email || "User").toUpperCase(); // Этих данных нет.
    let displayName = "User";

    const handleDrawerOpen = () => dispatch(setDrawerOpen(true));
    // const handleDrawerClose = () => dispatch(setDrawerOpen(false));

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleLogout = () => {
        appStorage.removeItem("authKey");
        appStorage.removeItem("key");
        window.location = "/";
    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClickMenuItem = (code) => {
        if (code === "logout") {
            handleLogout();
        } else {
            dispatch(setCurrentPage(code));
            handleCloseUserMenu();
        }
    };

    const menuData = [
        {
            title: "Кошелёк",
            code: "wallet",
            // disabled: true,
            icon: <WalletIcon fontSize="small" />,
        },
        {
            title: "Медицинская карта",
            code: "x_med",
            disabled: true,
            icon: <MedicalInformationIcon fontSize="small" />,
        },
        {
            title: "Мои врачи",
            code: "x_doc",
            disabled: true,
            icon: <MedicalServicesIcon fontSize="small" />,
        },
        {
            title: TXT.patientList,
            code: "patientList",
            icon: <AssignmentIndIcon fontSize="small" />,
        },
        {
            title: TXT.friendList,
            code: "friendList",
            icon: <GroupIcon fontSize="small" />,
        },
        {
            title: TXT.applicationList,
            code: "applicationList",
            icon: <DnsIcon fontSize="small" />,
        },
        {
            title: "Мои друзья",
            code: "x_fri",
            disabled: true,
            icon: <GroupIcon fontSize="small" />,
        },
        {
            title: TXT.inviteColleague,
            code: "referral",
            icon: <QrCodeIcon fontSize="small" />,
        },
        {
            title: "Выход",
            code: "logout",
            icon: <LogoutIcon fontSize="small" />,
        },
    ];


    return <AppBar
        position="fixed"
        open={open}
        sx={{ background: "linear-gradient(90deg, rgb(48, 68, 88) 8%, rgb(57, 180, 226) 22%)" }}
    >
        <Toolbar variant="dense">
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
                <MenuIcon />
            </IconButton>
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ marginRight: 4, flexGrow: 1 }}
            >
                Личный кабинет
            </Typography>

            {/* <Menu /> */}

            <Box sx={{ flexGrow: 0 }}>
                <Tooltip title={`Профиль`}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt={displayName}>
                            <PersonIcon fontSize="large" />
                        </Avatar>
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >

                    {menuData.map(menuItem => <MenuItem
                        disabled={!!menuItem.disabled}
                        selected={menuItem.code === currentPage}
                        key={menuItem.code}
                        onClick={() => handleClickMenuItem(menuItem.code)}
                    >
                        <ListItemIcon>
                            {menuItem.icon}
                        </ListItemIcon>
                        <ListItemText>{menuItem.title}</ListItemText>
                    </MenuItem>)}

                </Menu>
            </Box>
        </Toolbar>
    </AppBar>
}
